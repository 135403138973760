body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

h1 {
    text-align: center;
    margin: auto;
    font-size: 2.5em;
    color: #fff;
}

.card {
    background-color: #000; 
    border: none;
    border-radius: 10px;
    color: #fff;
}

.card-title {
    font-weight: bold; 
    text-align: center;
    font-size: 1.2em;
}

.card-text {
    color: black;
}

.star-rating {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.star {
    font-size: 1.5em;
    color: gold; /* Gold for filled stars */
}

.star.empty {
    color: #444; /* Dimmed color for empty stars */
}

@media only screen and (max-width: 480px) {
    h1 {
        font-size: 2em;
    }

    .card-title {
        font-size: 1em;
    }

    .card-text {
        font-size: 0.9em;
        color: black;
    }

    .star-rating {
        margin-top: 5px;
    }
}
