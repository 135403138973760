/* Grid Layout Styles */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
  gap: 1em; /* Adjust gap for spacing */
  justify-content: center;
  padding: 20px;
}

.grid-item {
  position: relative;
  overflow: hidden; /* Ensures overflow is hidden for hover effects */
  border-radius: 15px;
  transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.grid-img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Maintain aspect ratio and fill the container */
  transition: transform 0.3s ease-in-out; /* Smooth hover zoom effect */
}

.grid-item:hover .grid-img {
  transform: scale(1.05); /* Slight zoom effect on hover */
  filter: brightness(0.9); /* Slightly darken on hover */
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.full-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Add a shadow for better contrast */
}

/* PhotosPage Container */
.photos-page-container {
  width: 100%;
  color: #fff;
  padding: 40px 20px; /* More padding for better spacing */
  background: rgba(0, 0, 0, 0.5), url('../components/wooded-scene.jpg') no-repeat center center/cover;
  background-blend-mode: overlay; /* Improved overlay blend mode */
  text-align: center;
}

h1 {
  font-size: 3.5em;
  font-weight: bold;
  color: #fff;
  margin-bottom: 40px; /* More space below the heading */
  max-width: 600px;
  margin: 0 auto; /* Center the heading */
  white-space: pre-line;
}

/* Media Queries */

/* For screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjusted column size for medium screens */
  }

  h1 {
    font-size: 3em;
    margin-top: 40px;
  }

  .photos-page-container {
    padding: 20px;
  }

  .modal-content {
    max-width: 95%; /* Ensure modal fits smaller screens */
    max-height: 95%;
  }
}

/* For screens smaller than 480px */
@media only screen and (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjusted column size for small screens */
  }

  h1 {
    font-size: 2.5em;
  }

  .photos-page-container {
    padding: 10px;
  }

  .modal-content {
    max-width: 100%; /* Full width for smaller screens */
    max-height: 100%;
  }

  .full-img {
    border-radius: 5px; /* Smaller border radius for small screens */
  }
}
