/* GlobalStyles.css */
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: url('./components/wooded-scene.jpg') no-repeat center center fixed;
  background-color: rgba(0, 0, 0, 0.71); /* Lighter dark overlay */
  background-blend-mode: darken; /* Apply blend mode */
  background-size: cover;
  font-family: Arial, sans-serif;
  color: #fff;
}
