/* Specific styles for PriceList */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.pricepage-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 80px; /* Adjust top margin to account for fixed header height */
}

h1, .pricepage-content h1 {
  font-size: 3.5em;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  max-width: 600px;
  white-space: pre-line;
}

p, .pricepage-content p {
  font-size: 1.4em;
  color: #ddd;
  margin-bottom: 20px;
  max-width: 600px;
  white-space: pre-line;
}

.price-list-container {
  width: 100%;
  color: #000;
  padding: 20px;
  background: url('../components/wooded-scene.jpg') no-repeat center center/cover;
  background-color: rgba(0, 0, 0, 0.83); /* Lighter dark overlay */
  background-blend-mode: darken; /* Apply blend mode */
}

.green-line {
  width: 25%;
  border: 0;
  height: 5px;
  background-color: #059652;
  margin: 20px auto;
  position: relative;
  z-index: 2;
}

.price-list-content {
  text-align: center;
  width: 100%;
  color: #000;
}

.table-row {
  display: flex;
  flex-wrap: wrap; /* Allow tables to wrap to the next line on smaller screens */
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 50px;
  color: #000;
  gap: 20px; /* Add spacing between tables */
}

.table-row-bottom {
  display: flex;
  justify-content: center; /* Center the tables */
  gap: 20px; /* Space between the two tables */
  padding: 0 40px; /* Pull the row inward */
}

.table-row-bottom .table-wrapper {
  max-width: 40%; /* Adjust the table width to pull them inward */
}


.table-wrapper {
  flex: 1;
  max-width: 30%; /* Ensure three tables per row */
  margin-bottom: 20px; /* Add space at the bottom of each table */
} 

table {
  width: 100%; /* Ensure tables fill their container */
  margin: 0 auto; /* Center the table */
  border-collapse: collapse; /* Ensure compact borders */
  border-radius: 10px; /* Rounded corners for a polished look */
  overflow: hidden; /* Prevent overflow */
}

th, td {
  padding: 8px; /* Increased padding for better readability */
  text-align: left; /* Align content to the left */
  color: #000;
}

th {
  background-color: #059652; /* Green header box */
  color: #fff; /* White text for better contrast */
  font-size: 1.2em; /* Slightly larger font */
  font-weight: bold;
}

td {
  background-color: rgba(255, 255, 255, 0.6);
  color: #000;
  font-size: 1em; 
  font-weight: bold;
}

.table-description {
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  padding: 5px 10px;
  color: #111; /* Subtle text color */
  background-color: rgba(255, 255, 255, 0.5); /* Light background */
}

/* Ensure the "Price List" link stays white and doesn't show blue highlight */
.price-list-link:focus,
.price-list-link:active {
  color: white; /* Ensure text color stays white when focused or active */
  outline: none; /* Remove the default blue outline */
}

/* Media Queries */

/* For screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .pricepage-content {
    margin-top: 50px; /* Compact top margin */
    padding: 8px; /* Reduce padding slightly */
  }

  h1, .pricepage-content h1 {
    font-size: 2.2em; /* Slightly smaller heading */
  }

  p, .pricepage-content p {
    font-size: 0.9rem; /* Scaled text size */
  }

  .green-line {
    width: 30%;
    margin-left: auto;
    margin-right: auto; /* Center align */
  }

  .table-row {
    flex-wrap: wrap; /* Allow tables to stack if necessary */
    gap: 15px; /* Adjust spacing for smaller screens */
  }

  .table-wrapper {
    max-width: 45%; /* Ensure two tables per row */
  }
}

/* For screens smaller than 480px */
@media only screen and (max-width: 480px) {
  .pricepage-content {
    margin-top: 40px; /* Minimal spacing for smaller screens */
    padding: 5px;
  }

  h1, .pricepage-content h1 {
    font-size: 2em; /* Smaller heading size for phones */
  }

  p, .pricepage-content p {
    font-size: 0.9em; /* Compact paragraph size */
  }

  .green-line {
    width: 35%; /* Adjust line width for tiny screens */
  }

  .table-wrapper {
    margin-right: 0; /* Remove horizontal spacing */
    min-width: 100%; /* Make tables span the full width */
  }

  table {
    width: 100%; /* Tables fully stretch to screen width */
    margin: 0 auto; /* Center-align tables */
  }

  th, td {
    padding: 6px; /* Compact padding for touch-friendly sizes */
  }

  th {
    font-size: 1em; /* Adjust header text size */
  }

  td {
    font-size: 0.9em; /* Smaller text in table cells */
  }
}
