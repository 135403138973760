/* index.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background: transparent;
}
