/* HomePage.css */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
  background: transparent; /* Ensure transparency */
}

#root {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure consistent box model */
}

.home-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Ensure it covers full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../components/wooded-scene.jpg') no-repeat center center/cover;
  background-color: rgba(0, 0, 0, 0.74); /* Lighter dark overlay */
  background-blend-mode: darken; /* Apply blend mode */
}

.home-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.50); /* Lighter dark overlay */
  z-index: -1; /* Place behind the text */
}

.home-content {
  position: relative;
  z-index: 1; /* Place text above overlay */
  text-align: center;
  color: #fff; /* Ensure text color is readable */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Optional: add some padding for better spacing */
  height: 100%; /* Ensure it takes full height of the container */
}

h1, .home-content h1 {
  font-size: 56px;
  font-family: 'Roboto', sans-serif; /* Corrected the font-family */
  font-weight: bold;
  color: #fff; /* Ensure the heading is readable */
  margin-bottom: 20px;
  max-width: 600px;
  white-space: pre-line; /* Allows for controlled line breaks */
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8); /* Add stronger text shadow for better contrast */
}

.green-line {
  width: 20%;
  border: 0;
  height: 4px;
  background-color: #059652; /* Green color for the line */
  margin: 20px 0; /* Add some space around the line */
}

p, .home-content p {
  font-size: 1.2em; /* Adjust font size for paragraph */
  color: #ddd; /* Ensure the paragraph text is readable */
  margin-bottom: 20px;
  max-width: 600px;
  white-space: pre-line; /* Allows for controlled line breaks */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8); /* Add stronger text shadow for better contrast */
}

.view-price-list-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em; /* Adjust font size for button text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for better visibility */
}

.view-price-list-btn:hover {
  background-color: #218838;
}

/* Media Queries */

/* For screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .home-container {
    padding: 20px;
  }
  
  .home-content {
    padding: 40px; /* Add more padding for better spacing */
  }

  h1, .home-content h1 {
    font-size: 32px;
  }

  p, .home-content p {
    font-size: 1em;
  }

  .view-price-list-btn {
    font-size: 1em;
    padding: 8px 16px;
  }
}

/* For screens smaller than 480px */
@media only screen and (max-width: 480px) {
  .home-content {
    padding: 20px; /* Adjust padding */
  }

  h1, .home-content h1 {
    font-size: 24px;
  }

  p, .home-content p {
    font-size: 0.9em;
  }

  .view-price-list-btn {
    font-size: 0.9em;
    padding: 6px 12px;
  }
}
