.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background: transparent; /* Make the background transparent */
  position: fixed; 
  top: 0; 
  left: 0;
  z-index: 1000; 
  height: 60px; 
  box-shadow: none; /* Remove box shadow */
  border: none; /* Ensure there's no border */
}

.header * {
  visibility: visible; /* Ensure content inside the header is visible */
}

a {
  text-decoration: none;
  color: inherit;
}

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none; /* Remove underline from link */
  color: white; /* Ensure text color is white */
}

.logo-link:visited {
  color: white; /* Override visited link color */
  text-decoration: none;
}

.logo-link:hover {
  text-decoration: none;
  color: #059652 !important; /* Ensure consistent hover color */
}

.logo-link:focus,
.logo-link:active {
  color: white;
  outline: none;
}

.logo {
  height: 120px; /* Adjust size as needed */
  margin-right: 20px; /* Space between logo and text */
}

.logo-text {
  font-size: 1.5em; /* Adjust font size as needed */
  font-weight: bold;
  color: white;
}

.logo-link:focus .logo-text, .logo-link:active .logo-text { 
  outline: none; /* Remove the blue outline */ 
}

.nav ul {
  list-style-type: none;
  display: flex;
  gap: 30px;
}

.nav li {
  margin: 0;
}

.nav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.nav a:visited {
  color: white; /* Override visited link color */
}

.nav a:hover {
  color: #059652; /* Ensure consistent hover color */
}

.hamburger {
  display: none; /* Hide by default */
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px;
}

@media (max-width: 768px) {
  .nav ul {
    display: none; /* Hide nav items on small screens */
    flex-direction: column; /* Arrange items in a column */
    align-items: center; /* Center the items */
    position: absolute;
    top: 60px; /* Adjust position based on header height */
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85); /* Background for nav items */
    text-align: center; /* Center the text */
    padding: 0;
    margin: 0;
  }

  .nav.open ul {
    display: flex;
  }

  .nav ul li {
    width: 100%; /* Ensure each nav item takes full width */
    padding: 15px 0; /* Add padding for better spacing */
    border-top: 1px solid rgba(255, 255, 255, 0.2); /* Optional: add border for separation */
  }

  .nav ul li:first-child {
    border-top: none; /* Remove border for the first item */
  }

  .hamburger {
    display: flex; /* Show hamburger menu on small screens */
  }
}
